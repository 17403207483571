<template>
  <div>
    <template v-if="isAdmin || isTeacher">
      <div :class="`tpos tpos-admin ${practiceType}`">
        <div class="l-block">
          <div class="ileft">
            <b style="font-size:18px">{{ replace(title) }}</b>
            <div v-if="question_count">
              <span
                >Questions: <b>{{ question_count }}</b></span
              >
            </div>
          </div>
        </div>
        <div class="admin-r-block">
          <router-link
            v-if="published"
            class="start_test start mr-3"
            style="background-color: var(--themeColor)"
            :to="{
              name: isPhone ? 'SATTestLockPage' : 'SATTestLockPage',
              params: {
                practiceType:
                  practiceType === 'composePractices' ||
                  practiceType === 'adaptivePractices'
                    ? practiceType
                    : 'singlePractice',
                id: id
              }
            }"
          >
            Start Test
          </router-link>
          <router-link
            class="start_test start browse"
            :to="{
              name: routerLink,
              query: { id: id }
            }"
          >
            <i class="fa fa-eye"></i> Browse
          </router-link>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="locked tpos">
        <div class="l-block">
          <b class="ileft" style="font-size:18px">
            {{ replace(title) }}
          </b>
        </div>
        <div class="admin-r-block lock">
          <template
            v-if="
              test.user_exams &&
                test.user_exams.length > 0 &&
                test.user_exams[0].is_finished === 0
            "
          >
            <el-tooltip
              class="item"
              effect="dark"
              :content="
                practiceType === 'composePractices' ||
                practiceType === 'adaptivePractices'
                  ? `Resume (starting from Module ${
                      test.user_exams[0].other_status.pastOrder
                        ? test.user_exams[0].other_status.pastOrder.sectionOrder
                        : 1
                    } #${
                      test.user_exams[0].other_status.pastOrder
                        ? test.user_exams[0].other_status.pastOrder
                            .questionOrder
                        : 1
                    })`
                  : `Resume (starting from #${
                      test.user_exams[0].other_status.pastOrder
                        ? test.user_exams[0].other_status.pastOrder
                            .questionOrder
                        : 1
                    })`
              "
              placement="top"
            >
              <router-link
                class="start_test start"
                style="background-color: var(--themeColor)"
                :to="{
                  name: isPhone ? 'SATMobileTest' : 'SATTest',
                  params: {
                    practiceType:
                      practiceType === 'composePractices' ||
                      practiceType === 'adaptivePractices'
                        ? practiceType
                        : 'singlePractice',
                    id: id
                  },
                  query: {
                    user_exam_id: test.user_exams[0].id
                  }
                }"
              >
                <i class="fa fa-play"></i>
                {{ $t("courses.Resume") }}
              </router-link>
            </el-tooltip>
          </template>
          <router-link
            v-else
            class="start_test start"
            style="background-color: var(--themeColor)"
            :to="{
              name: isPhone ? 'SATTestLockPage' : 'SATTestLockPage',
              params: {
                practiceType:
                  practiceType === 'composePractices' ||
                  practiceType === 'adaptivePractices'
                    ? practiceType
                    : 'singlePractice',
                id: id
              }
            }"
          >
            Start Test
          </router-link>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Theme from "@/common/theme";
import moment from "moment";
import role from "@/mixins/role.js";
import Lock from "@/views/components/Lock";
import { instant } from "@ivy-way/material";
import SAT from "@/apis/sat.js";

export default {
  components: {},
  mixins: [role],
  props: [
    "test",
    "title",
    "passage",
    "version",
    "cover",
    "id",
    "isCompose",
    "practiceType",
    "question_count",
    "section_count",
    "published",
    "isUnlocked"
  ],
  data() {
    return {
      type: "passage",
      myPassage: 1
    };
  },
  computed: {
    instant() {
      return instant;
    },
    isAdmin() {
      let isAdmin = this.isRoleAdmin();
      return isAdmin;
    },
    isTeacher() {
      let isTeacher = this.isRoleTeacher();
      return isTeacher;
    },
    theme() {
      return Theme.name;
    },
    isPhone() {
      let isPhone = false;
      if (document.body.clientWidth <= 768) {
        isPhone = true;
      }
      return isPhone;
    },
    routerLink() {
      if (this.practiceType === "composePractices") {
        return "SatComposePracticeDetailAll";
      } else if (this.practiceType === "adaptivePractices") {
        return "SatAdaptiveDetailAll";
      } else {
        return "SatPracticeDetailAll";
      }
    }
  },
  watch: {
    passage: {
      handler: function(newValue, oldValue) {
        // let type = "passage";
        this.myPassage = this.passage;
        if (this.passage === 0) {
          this.type = "single";
          this.myPassage = 1;
        } else {
          this.type = "passage";
          this.myPassage = this.passage;
        }
      },
      immediate: true
    }
  },
  mounted() {},
  methods: {
    replace(title) {
      return this.CompanyName
        ? title.replace("SATMocks", this.CompanyName)
        : title;
    },
    moment() {
      return moment();
    },
    async startTest() {
      const res = await SAT.TakePracticeUserExam(this.test.id);
      if (res && res.user_exam && res.user_exam.id && res.user_exam.id > 0) {
        this.$router.push({
          name: this.isPhone ? "SATMobileTest" : "SATTest",
          params: {
            practiceType:
              this.practiceType === "composePractices"
                ? this.practiceType
                : "singlePractice",
            id: this.id
          },
          query: {
            user_exam_id: res.user_exam.id
          }
        });
      }
    }
  }
};
</script>

<style scoped>
.tpos {
  display: block;
  margin: 10px 0;
  /* cursor: pointer; */
  border-radius: 10px;
  height: 80px;
  box-sizing: border-box;
  color: white;
  transition: 0.2s 0s;
  background: url(https://testadmit-sat.s3.ap-northeast-1.amazonaws.com/images/icons/p6.svg)
    no-repeat right bottom var(--themeColor);
  background-size: 23% 50%;
}
/* .locked.tpos {
  background-color: white;
  color: var(--themeColor);
} */
.composePractices {
  background: url(https://testadmit-sat.s3.ap-northeast-1.amazonaws.com/images/icons/p4.svg)
    no-repeat right bottom var(--themeColor);
  background-size: 23% 50%;
}
.singlePractice {
  background: url(https://testadmit-sat.s3.ap-northeast-1.amazonaws.com/images/icons/p6.svg)
    no-repeat right bottom var(--themeColor);
  background-size: 23% 50%;
}
.tpos-ivyway {
  text-align: center;
  box-shadow: none;
  background: none;
  /* background-color: #edf1f8; */
}
.tpos-ivyway .l-block .ileft {
  text-align: center;
}
.tpos:hover {
  background-color: white;
  color: var(--themeColor);
  box-shadow: 0 5px 35px 0 rgb(83 120 186 / 15%);
}
.tpos-admin:hover {
  color: var(--themeColor);
}
.tpos:hover h3 {
  color: var(--themeColor);
}
.tpos:hover .start {
  color: #fff !important;
  opacity: 1;
  top: 26px;
}

.tpos:hover .testresult {
  opacity: 1;
  bottom: 20px;
}
.tpos:hover .l-block {
  opacity: 0.4;
  color: var(--themeColor);
}
.tpos:hover .first_start {
  opacity: 1;
  top: 48px;
}

.r-block a:first-child {
  top: 0px;
  background-color: var(--themeColor);
}
.l-block {
  height: 100%;
  width: 100%;
  display: block;
  float: left;
  color: #fff;
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 0 20px;
}
.l-block .itop {
  margin-top: 10px;
  height: 40px;
  vertical-align: bottom;
}

.l-block p {
  font-size: 16px;
  margin-left: 20px;
  margin-top: 10px;
}
.ibottom {
  height: 70px;
  padding-left: 20px;
}
.itop h3,
.l-block p,
.ibottom label {
  display: inline-block;
}
.l-block .itop h3 {
  margin-bottom: 0;
  font-size: 24px;
  font-weight: normal;
  font-family: "Source Sans Pro", sans-serif;
}
.r-block {
  width: 100%;
  display: block;
  position: absolute;
  height: 120px;
  float: right;
  text-align: center;
}
.admin-r-block {
  width: 100%;
  display: flex;
  position: absolute;
  height: 80px;
  float: right;
  /* justify-content:space-around; */
  align-items: center;
  justify-content: center;
}
.r-block a:first-child {
  top: 0px;
  background-color: var(--themeColor);
}
.r-block a {
  min-width: 100px;
  height: 38px;
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  border-radius: 50em;
  opacity: 0;
  color: #fff;
  text-align: center;
  line-height: 34px;
  transition: 0.5s 0s;
  border: 2px solid white;
  padding: 0 1rem;
}
.lock.admin-r-block a {
  /* background-color: orange; */
  width: 120px;
  padding: 0 1rem;
}
.admin-r-block a {
  width: auto;
  padding: 0 1rem;
}

.admin-r-block a {
  /* min-width: 100px; */
  height: 32px;
  border-radius: 5px;
  opacity: 0;
  color: #fff;
  text-align: center;
  line-height: 32px;
  transition: 0.5s 0s;
  transition: 0.5s 0s;
  background-color: var(--themeColor);
  /* margin: 0 1rem; */
}
.admin-r-block a.browse {
  background-color: var(--themeColor);
}
.admin-r-block a:hover {
  box-shadow: 3px 5px 10px #aaa;
}
a {
  user-select: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;
}
@media screen and (max-width: 992px) {
  .tpos {
    background-color: white;
    box-shadow: 0 5px 35px 0 rgb(83 120 186 / 15%);
  }
  .l-block {
    width: 100%;
    color: #fff;
    padding: 0 10px;
  }
  .l-block .ileft {
    margin-left: 0;
    font-weight: 500;
  }
  .r-block {
    display: none;
  }
}
@media screen and (max-width: 992px) {
  .tpos .l-block {
    opacity: 1;
    color: var(--themeColor);
  }
  .tpos:hover .l-block {
    opacity: 1;
    color: var(--themeColor);
  }
  .locked {
    display: flex;
  }
  .locked .admin-r-block {
    position: relative;
    width: 150px;
    padding: 0 10px;
  }
  .locked .l-block {
    display: flex;
    flex: 1;
    width: auto;
    justify-content: left;
  }
  .l-block .ileft {
    height: auto;
  }
  .lock.admin-r-block a {
    background-color: orange;
    width: 120px;
    padding: 0 5px;
    margin: 0;
  }
  .tpos .start_test.start {
    color: #fff !important;
    opacity: 1;
    top: 26px;
    left: 0;
  }
  .r-block {
    height: auto;
  }
}
</style>
